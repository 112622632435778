import React from 'react'
import PropTypes from 'prop-types'

import Address from '../components/Address'

const AbbrCircle = props => (
  <abbr
    {...props}
    className="AbbrCircle white inline-block center circle h6 bg-gray black mr1 select-none"
  />
)

const Nbsp = props => <React.Fragment>&nbsp;</React.Fragment>

const LocationsItem = props => {
  return (
    <div className="h5 md-h6 lg-h5">
      {props.address ? <Address {...props.address} /> : null}
      <div className="mb1">
        {props.phoneNumber ? (
          <a href={`tel:${props.phoneNumber}`} className="block black">
            <AbbrCircle title="Telephone">T</AbbrCircle>
            {props.phoneNumber}
          </a>
        ) : (
          <Nbsp />
        )}
      </div>
      {props.email ? (
        <a href={`mailto:${props.email}`} className="block line-height-2 black">
          {props.email}
        </a>
      ) : (
        <Nbsp />
      )}
    </div>
  )
}

LocationsItem.propTypes = {
  address: PropTypes.object,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
}

export default LocationsItem
