import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import slugify from 'slugify'

// Ours
import getCtaIdFromSelect from '../utils/get-ctaid-from-select'
import SEO from '../components/SEO'
import { BlockWrapper } from '../components/MainBlocks'
import { H2, H3 } from '../components/Headings'
import CallToAction from '../components/CallToAction'
import LocationsItem from '../components/LocationsItem'
import { WordmarkGroup } from '../components/Logo'
import ImgSharp from '../components/ImgSharp'

const CareerListings = props => {
  let emptyMessage = (
    <div
      dangerouslySetInnerHTML={{
        __html: props.emptyMessage,
      }}
    />
  )

  if (
    !props.items ||
    !(props.items.length >= 1) ||
    (props.items.length === 1 &&
      props.items[0].title.toLowerCase().includes('placeholder'))
  ) {
    return emptyMessage
  }

  return (
    <ul className="col-12 block m0 p0 list-style-none mxn1 border-top border-gray">
      {props.items.map((item, index) => {
        if (
          !item ||
          !item.title ||
          item.title.toLowerCase().includes('placeholder')
        ) {
          return null
        }

        return (
          <li key={`CareerListings_${index}`} className="block">
            <a
              href={item.link.url}
              className="flex col-12 py1 border-bottom border-gray h6 sm-h5">
              <div className="px1 col-6 black">{item.title}</div>
              <div className="px1 col-3 black">{item.location}</div>
              <div className="px1 col-3 bold">
                {item.link.title ? item.link.title : 'Apply Online'}
              </div>
            </a>
          </li>
        )
      })}
    </ul>
  )
}

CareerListings.defaultProps = {
  items: [],
  emptyMessage: 'There are no postings right now.',
}

CareerListings.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  emptyMessage: PropTypes.node.isRequired,
}

const ContactPageItem = props => {
  let titleSlug = slugify(props.title.toLowerCase())
  return (
    <div className="md-flex col-12">
      <div className="sm-col-1 md-col-3 lg-col-2 relative md-pr2">
        <div className="mb2">
          <a className="inline-block" href={`#${titleSlug}`}>
            <H2 mt={0} fontWeight={600}>
              {props.title}
            </H2>
          </a>
        </div>
      </div>
      <div className="col-12 md-col-9 lg-col-10" id={titleSlug}>
        {props.children}
      </div>
    </div>
  )
}

const ContactPageLocation = props => {
  return (
    <React.Fragment>
      {props.featuredImage && props.featuredImage.node ? (
        <div className="col-9 pb3 ContactPageLocation-figure">
          <ImgSharp {...props.featuredImage.node} />
        </div>
      ) : (
        <div className="col-12 bg-magenta muted">
          <svg viewBox="0 0 1280 1280" className="mb0 block col-12 bg-magenta">
            <g transform="translate(340, 540) scale(3)">
              <WordmarkGroup color="white" />
            </g>
          </svg>
        </div>
      )}
      <H3 fontWeight={600}>{props.title}</H3>
      <LocationsItem {...props.locationDetails} email={props.email} />
    </React.Fragment>
  )
}

const ContactPageLocationListing = props => {
  return (
    <div className="sm-mxn1">
      <ul className="list-style-none m0 p0 sm-flex flex-wrap col-12">
        {props.edges.map(({ node }, index) => {
          if (!node || !node.slug || node.slug.includes('placeholder')) {
            return null
          }

          return (
            <li
              key={`${node.id}_${node.index}`}
              className="col-12 sm-col-6 md-col-4 sm-px1 mb4 pb3 lg-pb4">
              <ContactPageLocation
                {...node}
                email={`${node.slug}@${props.emailDomain}`}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

ContactPageLocationListing.defaultProps = {
  edges: [],
}

ContactPageLocationListing.propTypes = {
  edges: PropTypes.arrayOf(PropTypes.object),
  emailDomain: PropTypes.string.isRequired,
}

const ContactPage = props => {
  const data = props.data
  let title = data.contact.title
  let chosenCta = getCtaIdFromSelect(data.details)
  let emailDomain = data.wp.acfOptions.acfOptions.emailDomain
  let dataCareerPostings = data.contact.careers.postings

  return (
    <React.Fragment>
      <SEO title={title} />

      <div>
        <BlockWrapper maxWidth={6} bg="transparent">
          <h1 className="display-none">{title}</h1>
          <div className="mb4">
            <ContactPageItem title="Locations">
              <ContactPageLocationListing
                edges={data.locations.edges}
                emailDomain={emailDomain}
              />
            </ContactPageItem>
          </div>
          <div className="mb4">
            <ContactPageItem title={data.contact.careers.title}>
              <div className=" max-width-3">
                <div
                  className="h3 line-height-3 parent-p-m0"
                  dangerouslySetInnerHTML={{
                    __html: data.contact.careers.intro,
                  }}
                />
              </div>
              <CareerListings
                items={dataCareerPostings}
                emptyMessage={data.contact.careers.emptyMessage}
              />
            </ContactPageItem>
          </div>
        </BlockWrapper>
        <CallToAction ctaId={chosenCta} />
      </div>
    </React.Fragment>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query ContactQuery {
    wp {
      acfOptions {
        acfOptions {
          emailDomain
          secondaryEmails {
            title
            description
            emailLocalpart
          }
        }
      }
    }
    contact: wpPage(slug: { eq: "contact" }) {
      title
      details {
        description
        ctaSelect
      }
      careers {
        intro
        postings {
          title
          location
          link {
            title
            url
          }
        }
        email
        title
        emptyMessage
      }
    }
    wpPage(slug: { eq: "locations" }) {
      id
      title
      content
      details {
        description
        ctaSelect
      }
    }
    locations: allWpLocation {
      totalCount
      edges {
        node {
          id
          title
          slug
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  id
                  resize(width: 800, height: 800) {
                    src
                  }
                }
              }
            }
          }
          details {
            description
          }
          locationDetails {
            address {
              unit
              streetAddress
              city
              province
              postalCode
            }
            faxNumber
            phoneNumber
            primaryContact {
              name
              role
            }
          }
        }
      }
    }
  }
`
